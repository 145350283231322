import CYReuest from "./request";
import { BASE_URL, TIME_OUT } from "./request/config";
const cyRequest = new CYReuest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptor: (config: any) => {
      console.log("请求成功拦截器");
      return config;
    },
    requestInterceptorCatch: (err: any) => {
      console.log("请求失败拦截");
      return err;
    },
    responseInterceptor: (res: any) => {
      console.log("响应成功拦截");
      return res;
    },
    responseInterceptorCatch: (err: any) => {
      console.log("响应失败拦截");
      return err;
    },
  },
});
export default cyRequest;
