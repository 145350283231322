import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  // 关于我们
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  // 隐私协议
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () =>
      import(
        /* webpackChunkName: "privacy-policy" */ "../views/PrivacyPolicy.vue"
      ),
  },
  // 用户服务协议
  {
    path: "/customer-agreement",
    name: "customer-agreement",
    component: () =>
      import(
        /* webpackChunkName: "customer-agreement" */ "../views/CustomerAgreement.vue"
      ),
  },
  // perfect支付
  {
    path: "/perfect-money-type",
    name: "perfect-money-type",
    component: () =>
      import(
        /* webpackChunkName: "perfect-money-type" */ "../views/pay/PerfectPay/PerfectPayType.vue"
      ),
  },
  {
    path: "/perfect-success",
    name: "perfect-success",
    component: () =>
      import(
        /* webpackChunkName: "perfect-success" */ "../views/pay/PerfectPay/PerfectSuccess.vue"
      ),
  },
  {
    path: "/perfect-fail",
    name: "perfect-ail",
    component: () =>
      import(
        /* webpackChunkName: "perfect-fail" */ "../views/pay/PerfectPay/PerfectFail.vue"
      ),
  },
  // perfect支付

  // stic支付
  {
    path: "/stic-money-type",
    name: "stic-money-type",
    component: () =>
      import(
        /* webpackChunkName: "stic-money-type" */ "../views/pay/SticPay/SticPayType.vue"
      ),
  },
  {
    path: "/stic-success",
    name: "stic-success",
    component: () =>
      import(
        /* webpackChunkName: "stic-success" */ "../views/pay/SticPay/SticSuccess.vue"
      ),
  },
  {
    path: "/stic-fail",
    name: "stic-ail",
    component: () =>
      import(
        /* webpackChunkName: "stic-fail" */ "../views/pay/SticPay/SticFail.vue"
      ),
  },
  // stic支付

  // 电汇支付
  {
    path: "/telegraphic-money-type",
    name: "telegraphic-money-type",
    component: () =>
      import(
        /* webpackChunkName: "telegraphic-money-type" */ "../views/pay/TelegraphicPay/TelegraphicPayType.vue"
      ),
  },
  {
    path: "/telegraphic-list",
    name: "telegraphic-list",
    component: () =>
      import(
        /* webpackChunkName: "telegraphic-list" */ "../views/pay/TelegraphicPay/TelegraphicPayList.vue"
      ),
  },
  // 电汇支付

  // 提款
  {
    path: "/draw-money",
    name: "draw-money",
    component: () =>
      import(/* webpackChunkName: "draw-money" */ "../views/DrawMoney.vue"),
  },
  // 提款订单详情页
  {
    path: "/draw-money-info",
    name: "draw-money-info",
    component: () =>
      import(
        /* webpackChunkName: "draw-money-info" */ "../views/DrawMoneyInfo.vue"
      ),
  },
  // 提款订单记录
  {
    path: "/draw-money-list",
    name: "draw-money-list",
    component: () =>
      import(
        /* webpackChunkName: "draw-money-list" */ "../views/DrawMoneyList.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
