import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "amfe-flexible";
import { i18n } from "./lang";
Vue.config.productionTip = false;
import Vant from "vant";
import "vant/lib/index.css";
// import VConsole from "vconsole";
// const vConsole = new VConsole();
import VueClipBoard from "vue-clipboard2";
Vue.use(VueClipBoard);
Vue.use(Vant);
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
