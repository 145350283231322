
import { Component, Vue } from "vue-property-decorator";
import { getLocale } from "@/service/libs/index";
import { initI18nLang } from "./lang";

@Component({
  name: "App",
})
export default class App extends Vue {
  mounted() {
    console.log(121212);
    setTimeout(async () => {
      let query = this.$route.query;
      // let sysLang = (await getLocale()) as string;
      let sysLang = "";
      let lang = (query.lang as string) || sysLang || "zh";
      console.log("当前语言：" + lang);
      localStorage.setItem("locale", lang);
      initI18nLang(lang);
      this.$i18n.locale = lang;
    }, 300);
  }
}
