import Vue from "vue";
import VueI18n from "vue-i18n";
import { Locale } from "vant";
import enUS from "vant/lib/locale/lang/en-US";
import zhCN from "vant/lib/locale/lang/zh-CN";

import localUs from "./app_local_en_us";
import localCn from "./app_local_zh_cn";
import localTw from "./app_local_zh_tw";

import remoteCn from "./app_zh_CN.json";
import remoteUs from "./app_en.json";
import remoteTw from "./app_zh.json";

Vue.use(VueI18n);
const messages = {
  en: {
    ...enUS,
    ...localUs,
    ...remoteUs,
  },
  zh: {
    ...zhCN,
    ...localCn,
    ...remoteCn,
  },
  tw: {
    ...localTw,
    ...remoteTw,
  },
};
console.log(333);
console.log(messages.tw);
const i18n = new VueI18n({
  locale: localStorage.getItem("locale") || "zh",
  messages: messages,
});
function initI18nLang(lang: string) {
  console.log(111, lang, messages.en);
  if (lang === "en") {
    Locale.use(lang, messages.en);
  } else if (lang === "zh") {
    Locale.use(lang, messages.zh);
  } else if (lang === "tw") {
    Locale.use(lang, messages.tw);
  } else {
    return;
  }
  localStorage.setItem("locale", lang);
}
export { i18n, initI18nLang };
