
import { Component, Vue } from "vue-property-decorator";
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
import { Button, Notify } from "vant";
import { accountLoginRequest } from "@/service/api";
import { State, Getter, Action, Mutation } from "vuex-class";
import store from "@/store";
@Component({
  name: "HOME",
  components: {
    // HelloWorld,
    Button,
  },
})
export default class Home extends Vue {
  private msg = "test msg";
  private btnName() {
    return this.$i18n.t("app.hello");
  }
  // @Prop({require:false, type: Strig, default: 'Development'})
  // readonly job: string;
  get fullName(): string {
    return "this is computed";
  }
  public testMethods(): string {
    return "this is function";
  }
  private async testApi() {
    let result = await accountLoginRequest({
      name: "121",
      password: "12",
    });
    console.log(result);
  }
  private handleChangeLang(lang: any) {
    this.$i18n.locale = lang;
    localStorage.setItem("locale", lang);
  }

  //接近vuex中的写法
  @State((state) => state.user) private user!: string;
  @Getter("getUser") public getUser!: string;
  @Mutation("setUserMutations") private setUserMutations: any;
  @Action("setUserActions") private setUserActions: any;
  mounted() {
    console.log("~~~~~mounted");
    // Notify("asdfasf");
    this.testApi();
    console.log("msg:" + this.msg);
    console.log(this.user);
    console.log(this.getUser);
    this.setUserActions("CCC"); // this.$store.commit(""setUserActions, "bbb")
    console.log(this.user);
    this.setUserActions("ddd");
    store.dispatch("setUserActions", "ddd");
    console.log(this.user);
  }
}
