import axios from "axios";
import type { AxiosInstance } from "axios";
import { CYRequestInterceptors, CYRequestConfig } from "./types";
import { Toast } from "vant";

const DEFAULT_LOADING = true;
class CYRequest {
  instance: AxiosInstance;
  interceptors?: CYRequestInterceptors;
  showLoading: boolean;
  loading?: any;
  constructor(config: CYRequestConfig) {
    this.instance = axios.create(config);
    this.showLoading = config.showLoading ?? DEFAULT_LOADING;
    this.instance.interceptors.request.use(
      this.interceptors?.requestInterceptor,
      this.interceptors?.requestInterceptorCatch
    );
    this.instance.interceptors.response.use(
      this.interceptors?.responseInterceptor,
      this.interceptors?.responseInterceptorCatch
    );

    this.instance.interceptors.request.use(
      (config: any) => {
        console.log("所有实例都有拦截：请求拦截成功");
        if (this.showLoading) {
          Toast.loading({
            message: "加载中...",
            forbidClick: true,
            loadingType: "spinner",
            duration: 0,
          });
        }
        return config;
      },
      (err) => {
        console.log("所有实例都有的拦截，请求响应失败");
        return err;
      }
    );

    this.instance.interceptors.response.use(
      (res) => {
        console.log("所有实例都有的拦截，请求响应成功");
        this.loading?.close();
        Toast.clear();
        const data = res.data;
        if (data.returnCode === "-1001") {
          console.log("请求失败，错误信息");
        } else {
          return data;
        }
      },
      (err) => {
        console.log("所有实例都有的拦截，请求响应成功");
        this.loading?.close();
        Toast.clear();
        if (err.response.status === 404) {
          console.log("404");
        }
        return err;
      }
    );
  }
  request<T>(config: CYRequestConfig<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      if (config.showLoading === false) {
        this.showLoading = config.showLoading;
      }
      if (config.interceptors?.requestInterceptor) {
        config = config.interceptors.requestInterceptor(config);
      }
      this.instance
        .request<any, T>(config)
        .then((res: any) => {
          if (config.interceptors?.responseInterceptor) {
            res = config.interceptors.responseInterceptor(res);
          }
          this.showLoading = DEFAULT_LOADING;
          resolve(res);
        })
        .catch((err: any) => {
          this.showLoading = DEFAULT_LOADING;
          reject(err);
        });
    });
  }
  get<T>(config: CYRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: "GET" });
  }
  post<T>(config: CYRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: "POST" });
  }
  delete<T>(config: CYRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: "DELETE" });
  }
  patch<T>(config: CYRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: "PATCH" });
  }
}
export default CYRequest;
