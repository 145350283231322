import cyRequest from "./index";
import { IAccount, IDataType, ILoginResult } from "./type";
enum LoginAPI {
  AccountLogin = "/login",
  LoginUserInfo = "/users",
  UserMenus = "/role/",
}

export function accountLoginRequest(account: IAccount) {
  return cyRequest.post<IDataType<ILoginResult>>({
    url: LoginAPI.AccountLogin,
    data: account,
  });
}
