import Vue from "vue";
import Vuex from "vuex";
import { Commit } from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: "aaa",
  },
  getters: {
    getUser(state) {
      return state.user;
    },
  },
  mutations: {
    setUserMutations(state, newUser) {
      state.user = newUser;
    },
  },
  actions: {
    // setUserActions(context: { commit: Commit }, newUser: Object) {
    //   context.commit("setUserMutations", newUser);
    // },
  },
  modules: {},
});
