let BASE_URL = "";
const TIME_OUT = 10000;
if (process.env.NODE_ENV === "development") {
  BASE_URL = "http://baidu1.com";
} else if (process.env.NODE_ENV === "production") {
  BASE_URL = "http://baidu2.com";
} else {
  BASE_URL = "http://baidu3.com";
}
export { BASE_URL, TIME_OUT };
